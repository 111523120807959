import React, { useContext, useState, useEffect } from 'react';
import { Radio} from 'antd';
import ContextProvider  from '../../context/ContextProvider';
import {calculaRespuesta} from '../../helpers/helpers';

import './Normas.scss';

const Normas = () => {
  const capturaRespuestas = useContext(ContextProvider);
  const {respuestas, setRespuestas} = capturaRespuestas;
  const [total, setTotal] = useState(0);

  const handleSizeChange = (e) => {
    setRespuestas({
      ...respuestas,
      [e.target.name]:e.target.value
    })    
  };
  
  useEffect(() => {
    const totalRespuestas= calculaRespuesta(respuestas);
    setTotal(totalRespuestas);
  }, [respuestas, setRespuestas])

  return (
    <div className='contenedor'>
      <div className='title'>
        <div className='indicacion'>

        </div>
        <div className='evaluacion'>
          <p className='item1'>Nunca ( 1 )</p>
          <p className='item2'>A veces ( 2 )</p>
          <p className='item3'>Casi siempre ( 3 )</p>
        </div>
      </div>
      <div className='item'>
        <div className='indicacion'>
          <p>Saludas cuando llegas.</p>
        </div>
        <div className='evaluacion'>
          <Radio.Group name="q1" className='items_radio' onChange={handleSizeChange}>
            <Radio.Button className='itemM' value="1">1 punto</Radio.Button>
            <Radio.Button className='itemM' value="2">2 punto</Radio.Button>
            <Radio.Button className='itemM' value="3">3 punto</Radio.Button>
          </Radio.Group>
        </div>
      </div>
      <div className='item_par'>
        <div className='indicacion'>
          <p>Te despides cuando te vas.</p>
        </div>
        <div className='evaluacion'>
          <Radio.Group name='q2' className='items_radio' onChange={handleSizeChange}>
            <Radio.Button className='itemM' value="1">1 punto</Radio.Button>
            <Radio.Button className='itemM' value="2">2 punto</Radio.Button>
            <Radio.Button className='itemM' value="3">3 punto</Radio.Button>
          </Radio.Group>
        </div>
      </div>
      <div className='item'>
        <div className='indicacion'>
          <p>Limpias donde has ensuciado.</p>
        </div>
        <div className='evaluacion'>
          <Radio.Group name="q3" className='items_radio' onChange={handleSizeChange}>
            <Radio.Button className='itemM' value="1">1 punto</Radio.Button>
            <Radio.Button className='itemM' value="2">2 punto</Radio.Button>
            <Radio.Button className='itemM' value="3">3 punto</Radio.Button>
          </Radio.Group>
        </div>
      </div>
      <div className='item_par'>
        <div className='indicacion'>
          <p>Cumples lo que prometes.</p>
        </div>
        <div className='evaluacion'>
          <Radio.Group name='q4' className='items_radio' onChange={handleSizeChange}>
            <Radio.Button className='itemM' value="1">1 punto</Radio.Button>
            <Radio.Button className='itemM' value="2">2 punto</Radio.Button>
            <Radio.Button className='itemM' value="3">3 punto</Radio.Button>
          </Radio.Group>
        </div>
      </div>
      <div className='item'>
        <div className='indicacion'>
          <p>Apagas los focos que no utilizas.</p>
        </div>
        <div className='evaluacion'>
          <Radio.Group name="q5" className='items_radio' onChange={handleSizeChange}>
            <Radio.Button className='itemM' value="1">1 punto</Radio.Button>
            <Radio.Button className='itemM' value="2">2 punto</Radio.Button>
            <Radio.Button className='itemM' value="3">3 punto</Radio.Button>
          </Radio.Group>
        </div>
      </div>
      <div className='item_par'>
        <div className='indicacion'>
          <p>Contestas si te hablan.</p>
        </div>
        <div className='evaluacion'>
          <Radio.Group name='q6' className='items_radio' onChange={handleSizeChange}>
            <Radio.Button className='itemM' value="1">1 punto</Radio.Button>
            <Radio.Button className='itemM' value="2">2 punto</Radio.Button>
            <Radio.Button className='itemM' value="3">3 punto</Radio.Button>
          </Radio.Group>
        </div>
      </div>
      <div className='item'>
        <div className='indicacion'>
          <p>Cierras la puerta que has abierto.</p>
        </div>
        <div className='evaluacion'>
          <Radio.Group name="q7" className='items_radio' onChange={handleSizeChange}>
            <Radio.Button className='itemM' value="1">1 punto</Radio.Button>
            <Radio.Button className='itemM' value="2">2 punto</Radio.Button>
            <Radio.Button className='itemM' value="3">3 punto</Radio.Button>
          </Radio.Group>
        </div>
      </div>
      <div className='item_par'>
        <div className='indicacion'>
          <p>Te disculpas si has ofendido a alguien.</p>
        </div>
        <div className='evaluacion'>
          <Radio.Group name='q8' className='items_radio' onChange={handleSizeChange}>
            <Radio.Button className='itemM' value="1">1 punto</Radio.Button>
            <Radio.Button className='itemM' value="2">2 punto</Radio.Button>
            <Radio.Button className='itemM' value="3">3 punto</Radio.Button>
          </Radio.Group>
        </div>
      </div>
      <div className='item'>
        <div className='indicacion'>
          <p>Argumentas cuando acusas.</p>
        </div>
        <div className='evaluacion'>
          <Radio.Group name="q9" className='items_radio' onChange={handleSizeChange}>
            <Radio.Button className='itemM' value="1">1 punto</Radio.Button>
            <Radio.Button className='itemM' value="2">2 punto</Radio.Button>
            <Radio.Button className='itemM' value="3">3 punto</Radio.Button>
          </Radio.Group>
        </div>
      </div>
      <div className='item_par'>
        <div className='indicacion'>
          <p>Evitar criticar cuando no sabes hacerlo mejor.</p>
        </div>
        <div className='evaluacion'>
          <Radio.Group name='q10' className='items_radio' onChange={handleSizeChange}>
            <Radio.Button className='itemM' value="1">1 punto</Radio.Button>
            <Radio.Button className='itemM' value="2">2 punto</Radio.Button>
            <Radio.Button className='itemM' value="3">3 punto</Radio.Button>
          </Radio.Group>
        </div>
      </div>
      <div className='item'>
        <div className='indicacion'>
          <p>Pagas lo que compras.</p>
        </div>
        <div className='evaluacion'>
          <Radio.Group name="q11" className='items_radio' onChange={handleSizeChange}>
            <Radio.Button className='itemM' value="1">1 punto</Radio.Button>
            <Radio.Button className='itemM' value="2">2 punto</Radio.Button>
            <Radio.Button className='itemM' value="3">3 punto</Radio.Button>
          </Radio.Group>
        </div>
      </div>
      <div className='item_par'>
        <div className='indicacion'>
          <p>Devuelves lo que te prestan.</p>
        </div>
        <div className='evaluacion'>
          <Radio.Group name='q12' className='items_radio' onChange={handleSizeChange}>
            <Radio.Button className='itemM' value="1">1 punto</Radio.Button>
            <Radio.Button className='itemM' value="2">2 punto</Radio.Button>
            <Radio.Button className='itemM' value="3">3 punto</Radio.Button>
          </Radio.Group>
        </div>
      </div>
      <div className='item'>
        <div className='indicacion'>
          <p>Pides permiso te tomar algo que necesitas.</p>
        </div>
        <div className='evaluacion'>
          <Radio.Group name="q13" className='items_radio' onChange={handleSizeChange}>
            <Radio.Button className='itemM' value="1">1 punto</Radio.Button>
            <Radio.Button className='itemM' value="2">2 punto</Radio.Button>
            <Radio.Button className='itemM' value="3">3 punto</Radio.Button>
          </Radio.Group>
        </div>
      </div>
      <div className='item_par'>
        <div className='indicacion'>
          <p>Das las gracias cuando te hacen un favor.</p>
        </div>
        <div className='evaluacion'>
          <Radio.Group name='q14' className='items_radio' onChange={handleSizeChange}>
            <Radio.Button className='itemM' value="1">1 punto</Radio.Button>
            <Radio.Button className='itemM' value="2">2 punto</Radio.Button>
            <Radio.Button className='itemM' value="3">3 punto</Radio.Button>
          </Radio.Group>
        </div>
      </div>
      <div className='item'>
        <div className='indicacion'>
          <p>Preguntas cuando no sabes cómo funciona algo.</p>
        </div>
        <div className='evaluacion'>
          <Radio.Group name="q15" className='items_radio' onChange={handleSizeChange}>
            <Radio.Button className='itemM' value="1">1 punto</Radio.Button>
            <Radio.Button className='itemM' value="2">2 punto</Radio.Button>
            <Radio.Button className='itemM' value="3">3 punto</Radio.Button>
          </Radio.Group>
        </div>
      </div>
      <div className='totales'>
        <div className='indicacion'>
          <p>RESULTADO DE LAS RESPUESTAS</p>
        </div>
        <div className='evaluacion'>
          <p>{total}</p>          
        </div>
      </div>
            
    </div>
  )
}

export default Normas