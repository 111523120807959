import { createContext, useState } from "react";

const PaletaContext = createContext();


const ContextProvider = ({children}) => {
    const [respuestas, setRespuestas] = useState({
        q1: '', q2: '', q3: '',q4: '',q5: '',q6: '',q7: '',q8: '',q9: '',q10: '',q11: '',q12: '',q13: '',q14: '',q15: ''
    });

    return(
        <PaletaContext.Provider
            value = {{
                respuestas,
                setRespuestas
            }}
        >{children}</PaletaContext.Provider>
    )
}


export {
    ContextProvider
}

export default PaletaContext;